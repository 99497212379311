$(document).on('turbolinks:load', function() {
	var user_ids = typeof pre_selected_user_ids != 'undefined' ? pre_selected_user_ids : [];
	// var group_users_count = parseInt($('.group_users_count').val());
	var group_users_count = Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000;
	$(document).on('keyup', '#search-group-users', function(event) {
		if (event.key === "Enter") {
			return;
		}
		$.ajax({
			url: $(".users_path").val(),
			type: "GET",
			dataType: 'script',
			data: {search: $(this).val(), user_ids: user_ids, current_object_id: $('.group_id').val(), object_type: "Group"},
		});
		$('#search-group-users-list').show();
	});

	$(document).on('click', '.remove-search-select-user', function(){
		var removeItem = $(this).parent().find('input:hidden').val();
		user_ids = user_ids.filter(value => {
			return value != removeItem;
		})
		$(this).parent().remove();
	});

	$('#search-users-to-invite').keyup( (e) =>{
		const id = parseInt($('#users-for-invite').attr('value'));
		let name = e.target.value
    if (name.length == 0 || name.length >= 3){
    	$.ajax({
	      url: '/groups/'+ id +'/filter_users',
	      data: { name },
	      method: 'GET',
	      success: function (data) {
	        console.log(data)
	      },
	      error: function (data) {
	        console.log(data)
	      }
	    })
    }
	})

	$('#send-invite-group').click(function(){
    var email = $('#input-email-group').val();
    const id = parseInt($('#users-for-invite').attr('value'));
    const pattern = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let role = document.getElementById("formControlRole").value.toLowerCase();
    if(email.length  == 0){
      alert("please enter email address ");
    }
	else if (role.trim() === "") { 
	    alert("Please select role.");
	}
    else{
      if (email.match(pattern)) {
        $.ajax({
          url: '/groups/'+ id +'/invite_users_by_email',
          data: { email,role },
          method: 'GET',
          success: function (data) {
            console.log(data)
          },
          error: function (data) {
            console.log(data)
          }
        })
      }
      else{
        alert("please input valid email address like 'abc@gmail.com'");
      }
    }
  })

  $('#send-invite-company').click(function(){
    var email = $('#input-email').val();
    const id = parseInt($('#input-email-group').attr('value'));
    const elements = document.getElementsByClassName('job_management_board_filter_role_dropdown_text');
    const role = elements[0].textContent.trim().toLowerCase();
    const pattern = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if(email.length  == 0){
      alert("please enter email address ");
    }
    else{
      if (email.match(pattern)) {
        $.ajax({
          url: '/companies/'+ id +'/invite_user',
          data: { email,role },
          method: 'GET',
          success: function (data) {
            console.log(data)
          },
          error: function (data) {
            console.log(data)
          }
        })
      }
      else{
        alert("please input valid email address like 'abc@gmail.com'");
      }
    }
  })
});


