$(document).on('turbolinks:load', function() {
  "use strict";
  var user_ids = [];
  // var user_communities_count = parseInt($('.user_communities_count').val());
  var user_communities_count = Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000;
  $(document).on('keyup', '#search-user-communities', function(event){
    if (event.key === "Enter") {
    return;
     }
  $.ajax({
    url: $(".users_path").val(),
    type: "GET",
    dataType: 'script',
    data: {search: $(this).val(), user_ids: user_ids, current_object_id: $('.community_id').val(), object_type: "Community"},
    });
  $('#search-user-communities-list').show();
  });

  $(document).on('click', '.remove-search-select-user', function(){
    var removeItem = $(this).prev().prev().val();
    user_ids = jQuery.grep(user_ids, function(value) {
      return value !== removeItem;
    });
    $(this).parent().remove();
  });

  $('#search-users-for-invite').keyup( (e) =>{
    const id = parseInt($('#users-to-invite').attr('value'));
    let name = e.target.value
    if (name.length == 0 || name.length >= 3){
      $.ajax({
        url: '/communities/'+ id +'/filter_users',
        data: { name },
        method: 'GET',
        success: function (data) {
          console.log(data)
        },
        error: function (data) {
          console.log(data)
        }
      })
    }
  })

  $('#send-invite-community').click(function(){
    var email = $('#input-email-community').val();
    const id = window.location.pathname.split('/')[2];
    const pattern = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let role = document.getElementById("user_role").value.toLowerCase();
    if(email.length  == 0){
      alert("please enter email address ");
    }
    else if (role.trim() === "") { 
      alert("Please select role.");
    }
    else{
      if(email.match(pattern)){
        $.ajax({
          url: '/communities/'+ id +'/invite_users_by_email',
          data: { email,role },
          method: 'GET',
          success: function (data) {
            console.log(data)
          },
          error: function (data) {
            console.log(data)
          }
        })
      }
      else{
        alert("please enter valid email address like 'abc@gmail.com'");
      }
    }
  })
  $('#event_location').autoComplete({
    bootstrapVersion: '4',
    minLength: 2,
    resolverSettings: {
        url: '/cities/filter?type=select',
        fail: () => {}
    }
  })

  $(document).ready(function (){
    var defaultValue = $('#label_location').data('value');
    if (defaultValue) {
      $('[name="event[location]_text"]').autoComplete('set', { value: defaultValue, text: defaultValue })
    }
  })
});
