$(document).on('turbolinks:load', function() {
    jquery('#new_user_session').validate({
      rules: {
        'password': {
          required: true,
          notblank: true
          // minlength: 6,
          // maxlength: 50
        },
        'email': {
          required: {
            depends: function () {
              $(this).val($.trim($(this).val()));
              return true;
            }
          },
          isEmail: true,
          notblank: true,
          email: true,
        }
      },
      errorPlacement: function(error, element) {
        if(element.attr("name") == "password") {
          if (element.val().length == 0) {
            $("#wrong-credentials-error").text("This field is required.")
            $("#wrong-credentials-error").removeClass("hidden")
          }
          else if (element.val().length < 6) {
            $("#wrong-credentials-error").text("Please enter at least 6 characters.")
            $("#wrong-credentials-error").removeClass("hidden")
          }
        }
        else {
          error.insertAfter(element.parent())
        }
      }
    })
  
  
    jquery.validator.addMethod("notblank", function(value, element) {
      return (value !== '' && value.trim() !== '')
    }, "This field can't be blank.");
  
  
    jquery.validator.addMethod("isEmail", function(value, element) {
      var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      return regex.test(value);
    }, "Email is not valid.");
  
    jquery(".js-signin").unbind().click(function(e) {
      e.preventDefault();
      if (jquery('#new_user_session').valid()) {
        $.ajax({
          url: '/signin',
          type: "POST",
          data: {email: $('#email').val(), password: $('#password').val(), authenticity_token: $('[name="csrf-token"]')[0].content},
        }).done(function(object){
          console.log(object)
          if (object) {
            window.location.href = "/";
          }
          else {
            $('#wrong-credentials-error').text("Your credentials are incorrect!");
            $('#wrong-credentials-error').removeClass("hidden");
          }
        })
      }
      else {
        $('#wrong-credentials-error').removeClass("hidden");
      }
    })
  })
  