$(document).on('turbolinks:load', function() {
  $('body').on('keyup', '.connection_search', (e)=>{
    if(e.key == 'Enter'){
      $.ajax({
        url: "/connections",
        type: "GET",
        dataType: 'script',
        data: {search: $(e.target).val(), type: $(e.target).data('type') }
      });
    }
  });

  $(document).on('click', ".connected_item_close_link", function (event) {
    $(this).parent().remove();
  });
});
