$(document).on('turbolinks:load', function() {
  jquery('#password_reset').validate({
    rules: {
      'email': {
        required: true,
        isEmail: true,
        email: true,
        remote: {
          url: '/forgot_email_exist',
          sendParam: 'email',
          method: 'POST',
        }
      }
    },
    messages: {
      'email': {
        remote: 'Email does not exist.'
      }
    },
    errorPlacement: function(error, element) {
      error.insertAfter(element.parent())
    }
  })
  
  jquery.validator.addMethod("notblank", function(value, element) {
    return (value !== '' && value.trim() !== '')
  }, "This field can't be blank.");
  
  jquery.validator.addMethod("isEmail", function(value, element) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(value);
  }, "Email is not valid.");
})
