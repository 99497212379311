
  // require('./emojiPicker');
  // require('./emojis_a');
  require('./mentiony');
  
    $(document).on('click', 'a.group_profile_content_item_upvote_link', function (e) {
      e.preventDefault();
      var route = $(this).hasClass('active') ? 'vote_down' : 'vote_up';
      var url = `/feed/${$(this).data('id')}/${route}`;
      var a = this;
      $.post(url, {
        voteable_id: $(this).data('id'),
        voteable_type: $(this).data('type')
      }, function (response) {
        $(a).text('Upvote (' + response.upvote_count + ')');
        $(a).toggleClass('active');
      });
    });

    $(document).on('click', 'a.delete-post', function(e) {
        e.preventDefault();
        if (!confirm('Are you sure you want to delete this post?')) return;
        const $this = $(this)
        var id =  $(this).data('id');
        $.ajax({
          url: `/feed/${id}`,
          type: 'DELETE',
          success: function() {
            $this.closest('.post-wrapper').remove()
          }
        });
    });  