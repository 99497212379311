import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.min.css'

$(document).on('turbolinks:load', function() {
  $('.select2').select2({
    tags: true,
    placeholder: "Please select"
  });

  function formatImageData (data) {
    if (!data.id) { return data.text; }

    let imageUrl = $(data.element).data('img');
    if(imageUrl == undefined){
      imageUrl = "/assets/users/avatar-placeholder.png"
    }
    const subtitle = $(data.element).data('subtitle');
    var $result= $(
      `
        <div class="custom-option-wrap" >
          <div><img src="${imageUrl}" style="max-height: 40px; border-radius: 50%;"></div>
          <div style="margin-left: 10px; ">
            <div class="main-title" >${data.text}</div>
            <div class="subtitle">${subtitle}</div>
          </div>
        </div>
      `
    );
    return $result;
  };

  $("#share-with-emails-select").select2({
    templateResult: formatImageData,
    templateSelection: formatImageData,
    placeholder: "Add email or name...",
    width: '100%'
  });

  $('.share-with-email-select-wrap .select2-search__field').css('width', '100%');
});
