// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import * as bootstrap from "bootstrap"
import "../stylesheets/application"
import "jquery-validation/dist/jquery.validate";

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require('bootstrap')
// require('bootstrap/dist/js/bootstrap.bundle.min')
require('bootstrap-select/js/bootstrap-select')
require('jquery')
require('jquery-validation');
window.Swal = require('sweetalert2');
$.fn.selectpicker.Constructor.BootstrapVersion = '4.6.0';
require('bootstrap-autocomplete')
require('@nathanvda/cocoon')
try {
    require('@ckeditor/ckeditor5-build-classic')
} catch (e) {

}
require('cropper')
import Cropper from 'cropperjs'
window.Cropper = Cropper

import 'jquery-textcomplete';
import "emojionearea"
var moment = require('moment')
import "daterangepicker"
import LocalTime from "local-time"

try {
    LocalTime.start();
} catch (e) {

}
import Sortable from "sortablejs";
window.Sortable = require("sortablejs")

require("./mentiony")
require('./gijgo')
require('./script')
require('./user')
require('./user_tracking')
require('./registrations/form')
require('./sessions/form')
require('./passwords/form')
require('./conversation')
require('./groups')
require('./communities')
require('./settings')
require('./user_query')
require('./favorites');
require('./job')
require('./summit')
require('./organization')
require('./connections')
require('./companies')
require('./follows')
require('./event')
require('./item_folder');
require('./select2setup');
require('./registrations/company_form')
require('./schools')
require('./feed')
require('./webinar_countdown')

global.moment = moment;
window.moment = moment;
window.Modal = bootstrap.Modal;

import "controllers"
