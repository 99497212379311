$(document).on('turbolinks:load', function() {
  $(document).on('click', '.favorite_obj', function(event){
    event.preventDefault();
    $.ajax({
      url: '/favorite/'+ $(this).data('type') + '/' + $(this).data('id'),
      type: "POST",
      dataType: 'script'
    });
  });

  $(document).on('click', '.unfavorite_obj', function(event){
    event.preventDefault();
    $.ajax({
      url: '/unfavorite/' + $(this).data('type') + '/' + $(this).data('id'),
      type: "POST",
      dataType: 'script'
    });
  });
});
