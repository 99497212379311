import Sortable from 'sortablejs';
$(document).ready(function() {
$(document).on('turbolinks:load', function () {
  String.prototype.titleize = function () {
    let str = this.replace(/_/g, ' ')
    return str.replace(
      /\w\S*/g, str => str.charAt(0).toUpperCase() + str.substr(1).toLowerCase()
    )
  }

  $('form.edit_user select.skills, form#user_form select.skills').change(e => {
    let $this = $(e.currentTarget)
    $('.skills-section').toggle($this.find('option:selected').length > 0)
    let html = ''
    let skillLevels = $this.data('skill-levels') || {}
    let skillLevel = ''
    for (let skill in skillLevels) {
      skillLevel += `<option value="${skillLevels[skill]}">${skill.titleize()}</option>`
    }
    $this.find('option:selected').each((key, item) => {
      let level = $(item).data('level')
      html += `<span class="d-flex personal-profile__skill_row">
                      <span class="d-flex personal-profile__skill_name">
                        ${$(item).text()}
                      </span>

                      <span class="d-inline-block personal-profile__skill_level_select_container">
                        <select data-skill-id="${$(item).attr('value')}" name="user[skill_level][${$(item).attr('value')}]" class="personal_profile_select skill-level" title="Select level">
                          ${skillLevel.replace(new RegExp(`(value="${level}")`, 'g'), "$1 selected")}
                        </select>
                      </span>
                    </span>`
    })
    $('#skill-levels-here').toggle(html !== '').html(html)
    try {
      $('#skill-levels-here select').selectpicker()
      $('#skill-levels-here select').addClass('selectpicker')
    } catch (e) {

    }
  }).trigger('change');

  jquery('form.edit_user').validate({
    rules: {
      'user[user_type]': {
        required: true
      }
    },
    errorPlacement: function (error, element) {
      if (element.attr("name") === "user[user_type]") {
        element.parent().after(error)
      } else {
        error.insertAfter(element)
      }
    }
  })

  jquery('#user_form').validate({
    rules: {
      'user[first_name]': {
        required: true,
        notblank: true,
        minlength: 1,
        maxlength: 50,
        validName: true,
      },
      'user[last_name]': {
        required: true,
        notblank: true,
        minlength: 1,
        maxlength: 50,
        validName: true,
      },
      'user[password]': {
        required: true,
        notblank: true,
        minlength: 6,
        maxlength: 50
      },
      'user[password_confirmation]': {
        required: true,
        notblank: true,
        minlength: 6,
        maxlength: 50,
        equalTo: "#user_password"
      },
      'user[email]': {
        required: true,
        isEmail: true,
        email: true,
        remote: {
          url: `/email_exist?slug=` + (jquery('#user_form').length ? (jquery('#user_form').attr('action').match(/users\/(.+)$/) || "") : ""),
          sendParam: 'email',
          method: 'POST',
        }
      },
      'user[user_type]': {
        required: function (element) {
          return ['admin', 'company'].indexOf($('[name="user[role]"]').val()) < 0
        }
      }
    },
    errorPlacement: function (error, element) {
      if (element.attr("name") === "user[user_type]") {
        element.parent().after(error)
      } else {
        error.insertAfter(element)
      }
    },
    messages: {
      'user[email]': {
        remote: 'Email already exist.'
      }
    }
  });

  jquery.validator.addMethod("validName", function(value) {
    var regex = /^[A-Za-z'\-\s]+$/;
    return regex.test(value);
  }, "Name should consist of letters, apostrophe, or hyphens only.");

  var classicEditorInstances = [];

  $('.text_area_ck').each((index, item) => {
    ClassicEditor
      .create(item)
      .then(editor => {
        classicEditorInstances.push(editor);

        editor.model.document.on('change', () => {
          var plainTextContent = editor.getData().replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ');
          var charCount = plainTextContent.length;

          var charactersLeft = 2275 - charCount;
          $('#charCountElement').text(`${charactersLeft} characters left`);
          $('#charCountElement').css('color', charactersLeft < 0 ? 'red' : '');

          if (charactersLeft < 0) {
            setTimeout(function () {
              editor.setData(stripHtmlTags(plainTextContent.slice(0, 2274)));
            }, 0);

            Swal.fire({
              title: 'Error',
              text: 'Character limit exceeded (maximum 2275 characters)',
              icon: 'error',
            });
          }
        });
      })
      .catch(error => {
        console.error('There was a problem initializing the editor.', error);
      });
  });

  function stripHtmlTags(html) {
    var doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  }


  jquery.validator.addMethod("notblank", function (value) {
    return (value !== '' && value.trim() !== '');
  }, "This field can't be blank.");

  jquery.validator.addMethod("isEmail", function (value) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(value);
  }, "Email is not valid.");

  $('body').on('click', '#user_saved_list', (e) => {
    $.ajax({
      url: '/item_folders',
      type: 'GET',
      dataType: 'script',
      data: { user_id: ($(e.target).data('id') || $(e.target).parent().data('id')) }
    })
  })

  $('body').on('keyup', '.users_search', (e) => {
    $.ajax({
      url: '/users/filter',
      type: 'GET',
      dataType: 'script',
      data: { search: $(e.target).val() }
    })
  })

  document.onkeydown = function () {
    if ($("#target").length > 0 && window.event.keyCode == '13') {
      remove_extra_fields();
      $("#target").submit();
    }
  }

  $('body').on('keyup', '.search_for_select_box', function (e) {

    let checked_items
    try {
      checked_items = JSON.parse($('#checked_items_' + e.target.id).val());
    }
    catch (err) {
      null
    }
    $.ajax({
      url: '/users/search',
      type: 'GET',
      data: { param: e.target.id, search: this.value, checked_items: checked_items }
    });
  });

  $('body').on('change', '.user_search_fields', () => {
    if (/\/members$/.test(window.location.href.replace(/\?.+$/, '')) === false) {
      remove_extra_fields();
    }
    $("#target").submit();
  });


  $('body').on('change', '#partner_id', (e) => {
    $.ajax({
      url: "/admin/company_partners/display_sponsor_level",
      type: 'GET',
      data: { company_partner_id: $(e.target).val(), summit_id: $(e.target).data()['summit'] }
    });
  });

  function remove_extra_fields() {
    var skillInput = document.getElementById('skill');
    var nameInput = document.getElementById('name');
    var schoolInput = document.getElementById('school');
    var userTypeInput = document.getElementById('user_type_search');

    if (skillInput && skillInput.value === '') {
      $(skillInput).remove();
    }
    if (nameInput && nameInput.value === '') {
      $(nameInput).remove();
    }
    if (schoolInput && schoolInput.value === '') {
      $(schoolInput).remove();
    }
    if (userTypeInput && userTypeInput.value === '') {
      $(userTypeInput).remove();
    }
  }

  $('[name="travel_form[departure_city]"]').autoComplete({
      bootstrapVersion: '4',
        minLength: 2,
        resolverSettings: {
            url: '/cities/filter?type=select',
            fail: () => {}
      }
  });

  $(document).on('click', '#rank_user', function(e){
    var selectedValue = this.dataset.userId;
    $.ajax({
      url: '/users/'+ selectedValue +'/company_ranking',
      data: { rank: this.dataset.rank },
      type: 'POST',
      dataType: 'script',
    });
  });

  if ( document.getElementById('user-all-rankings') ) {
    var el       = document.getElementById("user-all-rankings");
    var user_id  = el.getAttribute('data-user-id');;
    var sortable = Sortable.create(el, {
      delay: 200,
      touchStartThreshold: 5,
      delayOnTouchOnly: false,
      onEnd: function(event) {
        var rankings_order = [];
        $(".rankings__item").each(function(e) {
          rankings_order.push($(this).attr('id').match(/(\d+)/g)[0])
        })
        $.ajax({
          type: "PATCH",
          dataType: 'script',
          url: "/users/update_rankings_position",
          data: {
            new_ranking_ids_order: rankings_order
          }
        });
      }
    });
  }
});
});
