$(document).on('turbolinks:load', function () {
    $('.user_profile_data_nav_item').click(function(e) {
       e.stopPropagation();
       e.preventDefault();
       var tabData = $(this).data("tab");
       $('.user_profile_data_nav_item').removeClass('active');
       $(this).addClass('active');
       $('.company_profile_content_left_item').removeClass('active');
       $('.company_profile_content_left_item[data-tab="' + tabData + '"]').addClass('active');
    });

    $('[name="location"]').autoComplete({
        bootstrapVersion: '4',
        minLength: 2,
        resolverSettings: {
          url: '/cities/filter?type=jobs',
          fail: () => {}
        },
        formatResult: function (item) {
          if (!item.hasOwnProperty('value')) {
            return `<div id="state-header">State</div><div>${item}</div>`
          } else {
            return `<div id="city-header">City</div><div>${item.text}</div>`
          }
        }
    });

    function updateHeaders() {
        var stateHeader = document.querySelectorAll('#state-header');
        if (stateHeader.length > 0) {
          stateHeader[0].style.fontWeight = "bold";
          stateHeader[0].style.display = 'block';

          for (var i = 1; i < stateHeader.length; i++) {
            stateHeader[i].style.display = 'none';
          }
        }

        var cityHeader = document.querySelectorAll('#city-header');
        if (cityHeader.length > 0) {
          cityHeader[0].style.display = 'block';
          cityHeader[0].style.fontWeight = 'bold';

          for (var i = 1; i < cityHeader.length; i++) {
            cityHeader[i].style.display = 'none';
          }
        }
    }

    setInterval(updateHeaders, 10);

    $('#job_company_jobable_id, #job_school_jobable_id').on('change', function () {
        var array = ['job_company_jobable_id', 'job_school_jobable_id'];
        var i = 0, len = array.length;
        while (i < len) {
            if (array[i] !== this.id) {
                $('#' + array[i]).val('');
                $('#' + array[i]).selectpicker('refresh');
            }
            i++;
        }
    });

    function remove_benifits_tag(e) {
        var text = e.target.parentElement.parentElement.parentElement.dataset["benfitsOption"];
        var regex = new RegExp(text + '\, |\, ' + text, 'g');
        var regex_last = new RegExp(text, 'g');
        $(".job_benifits button .filter-option-inner-inner").text($(".job_benifits button .filter-option-inner-inner").text().replace(regex, "").replace(regex_last, 'Select Benefits'));
        $('#job_benifits option[value="' + e.target.parentElement.parentElement.parentElement.dataset["benfitsVal"] + '"]').prop('selected', false);
        $('#job_benifits').trigger('change');
    }

    function remove_skill_tag(e) {
        var th = $(e.target);
        var selectedVal = th.parent().data('id');
        var jobSkillVal = document.getElementsByClassName('job_skill');
        for (var i = 0; i < jobSkillVal.length; i++) {
            if (jobSkillVal[i].getAttribute("id") === selectedVal) {
                jobSkillVal[i].closest(".nested-fields").setAttribute("Style", "display: none;");
                if (jobSkillVal[i].parentElement.nextElementSibling) {
                    jobSkillVal[i].parentElement.nextElementSibling.value = 1;
                } else {
                    jobSkillVal[i].nextElementSibling.value = 1;
                }
            }
        }
        load_job_skills();
    }

    function append_selected_value(option_value, option_name, select_id = '1') {
        var selected_job_skill = "<div class='personal-profile__badge'>" + option_name + "</div>"
        $('.selected_job_skill').append(selected_job_skill);
    }

    function load_job_skills() {
        $('.selected_job_skill').html("");
        var jobSkillVal = document.getElementsByClassName('job_skill_select');
        for (var i = 0; i < jobSkillVal.length; i++) {
            if (jobSkillVal[i].value) {
                if (jobSkillVal[i].closest(".nested-fields").getAttribute("style") === "display: none;") {
                    continue;
                } else {
                    append_selected_value(jobSkillVal[i].value, jobSkillVal[i].options[jobSkillVal[i].selectedIndex].text);
                }
            }
        }
    }

    function append_selected_categoreis_value(option_value, option_name, select_id = '1') {
        var selected_job_skill = "<div class='personal-profile__badge'>" + option_name + "</div>"
        $('.selected_categories_skill').append(selected_job_skill);
    }

    function load_job_categories() {
        $('.selected_categories_skill').html("");
        var jobCatVal = $('#categoriesSelect');
        var selected_options = jobCatVal.find(":selected")
        if (selected_options.length > 0) {
            for (var i = 0; i < selected_options.length; i++) {
                append_selected_categoreis_value(selected_options[i].value, selected_options[i].text);
            }
        }
    }


    function load_benifits() {
        var benifits_val = $('#job_benifits').val();
        var benefits_option = $('[data-benfits-val]');
        if (benefits_option.length > 0) {
            for (var i = 0; i < benefits_option.length; i++) {
                if ($.inArray(benefits_option[i].dataset["benfitsVal"], benifits_val) !== -1) {
                    benefits_option[i].classList.remove('d-none');
                    benefits_option[i].classList.add('d-block');
                } else {
                    benefits_option[i].classList.add('d-none');
                    benefits_option[i].classList.remove('d-block');
                }
            }
        }
    }

    function jobInterested(th) {
        var currentBtn = th;
        $.ajax({
            url: '/jobs/' + th.data('id') + '/interested',
            type: 'GET',
            dateType: 'JSON',
            success: function () {
                currentBtn.text("Interested");
                currentBtn.addClass('disabled');
            }
        });
    }

    $('body').on('click', '.create_job_skills_tag_remove_link', (e) => {
        remove_skill_tag(e);
    });

    $('body').on('click', '.create_job_benefit_remove_link', (e) => {
        remove_benifits_tag(e);
    });

    $('body').on('change', '#job_benifits', () => {
        load_benifits();
    });

    $('body').on('change', '.job_skill_select', () => {
        load_job_skills();
    });

    $('body').on('change', '.job_categories_select', () => {
        load_job_categories();
    });

    $('.job_skill_container').on('cocoon:after-remove', function () {
        load_job_skills();
    });
    $('body').on('click', '.interested_job', function () {
        jobInterested($(this));
    });

    load_job_skills();
    load_job_categories();
    load_benifits();

    $('body').on('click', '.job_type_filter', (e) => {
        $('.jobs_side_filter').submit();
    })

    $('.create_job_step_item').click(function (e) {
        e.stopPropagation();
        e.preventDefault();
        var tabData = $(this).data("tab");
        $('.create_job_step_item').removeClass('active');
        $('.create_job_tab_container').addClass('d-none');
        $('.create_job_step_item[data-tab="' + tabData + '"]').addClass('active');
        $('.' + tabData).removeClass('d-none');
    });

    $('input[type="checkbox"]:not(.allow-toggle)').on('change', function () {
        // if ($('.job-management-board-checkbox:not(.allow-toggle)').length > 0) {
            // $('.job-management-board-checkbox:not(.allow-toggle)').prop('checked', false);
            if (this.checked) {
                $(this).prop('checked', true);
            } else {
                $(this).prop('checked', false);
            }
        // }
    });

    $('.create_form_add_input_btn.skills').on('click', function () {
        var multipleInputsContainer = $(this).siblings('span');
        var inputToClone = multipleInputsContainer.find("input").first().clone();
        inputToClone.val("");
        multipleInputsContainer.append(inputToClone);
    });

    $('.create_form_add_input_btn.with_delete').on('click', function () {
        var multipleInputsContainer = $(this).siblings('span');
        var inputToClone = multipleInputsContainer.find(".nested-fields").first().clone();
        inputToClone.find("input").val("");
        inputToClone.addClass("d-flex");
        multipleInputsContainer.append(inputToClone);
    });

    $(document).on('click', '.delete_cloned_input', function(e) {
        e.stopPropagation();
        e.preventDefault();

        $(this).parent().remove();
    });

    $('.add_location_input_btn').on('click', function () {
        var multipleInputsContainer = $(this).siblings('span');
        var inputToClone = multipleInputsContainer.find("input").first().clone();
        inputToClone.val("");
        multipleInputsContainer.append('<div class="input-group"></div>');
        multipleInputsContainer.find('.input-group').last().append(inputToClone);

        inputToClone.autoComplete({
            bootstrapVersion: '4',
            minLength: 2,
            resolverSettings: {
                url: '/cities/filter?type=select',
                fail: () => {}
            }
        });

        var cancelButton = $('<button type="button" class="btn delete-btn float-right ml-1" style="width: 40px; height: 40px; margin-top: 2px;"></button>');
        cancelButton.on('click', function () {
            $(this).parent().remove();
        });
        multipleInputsContainer.find('.input-group').last().append(cancelButton);
    });

    $('#approved_jobs_search, #pending_jobs_search').on('keyup', function (e) {
      var th = $(e.target);
      if (th.val().length >= 3 || e.keyCode === 8 ) {
        $.ajax({
          type: 'GET',
          url: th.data('url'),
          dataType: 'script',
          data: {
            'search' : th.val()
          },
        });
      }
    });

    jquery('#job_basic_form').validate({
        rules: {
            'job[name]': {
                required: true,
                notblank: true,
                minlength: 1,
                maxlength: 50
            },
            'job[company_jobable_id]': {
                select_jobablbe: true
            }
        },
        errorPlacement: function (error, element) {
            if (element[0].attributes['name'].value == 'job[company_jobable_id]') {
                error.insertAfter(element.parent())
            } else {
                error.insertAfter(element)
            }
        }
    })

    jquery.validator.addMethod("select_jobablbe", function (value, element) {
        if ($("#job_company_jobable_id").length > 0) {
            return ($("#job_company_jobable_id").val() !== '' || $("#job_school_jobable_id").val() !== '')
        }
    }, "This field can't be blank.");

    jquery.validator.addMethod("isEmail", function (value, element) {
        var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return regex.test(value);
    }, "Email is not valid.");
});
