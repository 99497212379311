$(document).on('turbolinks:load', function() {
  jquery('#user_quries').validate({
    // ignore: ".ignore",
    rules: {
      'user_query[first_name]': {
        required: true,
        notblank: true,
        minlength: 1,
        maxlength: 50
      },
      'user_query[last_name]': {
        required: true,
        notblank: true,
        minlength: 1,
        maxlength: 50
      },
      'user_query[email]': {
        required: true,
        isEmail: true,
        email: true
      },
        'agree_to_policy': {
        required: true      }
    },
    messages: {
    },
    errorPlacement: function(error, element) {
      if (element.attr('name') === 'agree_to_policy') {
        error.insertAfter(element.parent().parent());
      } else {
        error.insertAfter(element.parent());
      }
    }
  });


  jquery.validator.addMethod("notblank", function(value) {
    return (value !== '' && value.trim() !== '');
  }, "This field can't be blank.");

  jquery.validator.addMethod("isEmail", function(value) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(value);
  }, "Email is not valid.");
});
