$(document).on('turbolinks:load', function() {
  $("body").on("focus", ".center_search__input_wrap input", function () {
    $('.center_search').addClass('active');
  });

  $( '.group_profile_content_item_comments_item_inner .group_profile_content_item_top_left' ).on( "mouseenter", function(){
    $(this).parent().parent().find('.group_profile_avatar_info').addClass('show');
  }).on( "mouseleave", function(){
    let parentThis = this;
    setTimeout( function(){
      $(parentThis).parent().parent().find('.group_profile_avatar_info').removeClass('show');
    }, 2000 )
  });

  $("body").on("focusout", ".center_search__input_wrap input", function () {
    $('.center_search').removeClass('active');
  });

  $('body').on('click', '.create_job_save_btn', function(e) {
    var sponsor = $('#panelist_name').val();
    if (typeof sponsor !== 'undefined' && sponsor.length === 0){
      e.stopPropagation();
      e.preventDefault();
      $('#speaker_name_field_error').text('Speaker name is required.');
      $('#speaker_name_field_error').removeClass('d-none');
    }
  });

  $(".modal-tooltip-button").click(function () {
    var modalID = $(this).attr("data-target");
    var topOffset = 100;
    var thisLeft = $(this).offset().left;
    var thisRight = $(this).offset().right;
    var thisTop = $(this).offset().top - 20;
    var thisWidth = $(this).width();
    var thisHeight = $(this).height();
    var idWidth = $(modalID).width();
    var idHeight = $(modalID).height();
    $(modalID + " .modal-dialog").css({ right: thisRight, top: thisTop });
  });

  var participantsElement = document.getElementById("other_persons");

  if (participantsElement) {
    participantsElement.addEventListener("wheel", scrollParticipants);
  }

  function scrollParticipants(e) {
    e.preventDefault();
    const container = document.getElementById("other_persons");
    const containerScrollPosition = document.getElementById("other_persons").scrollLeft;
    container.scrollTo({
      top: 0,
      left: containerScrollPosition + e.deltaY,
      behaviour: "smooth"
    });
  }

  $('.webinar_room_chat_toggle').on('click', function(e) {
    e.stopPropagation();
    e.preventDefault();

    var tabData = $(this).data("tab");

    $('.webinar_room_chat_toggle').removeClass('active');
    $('.webinar_room_speakers_items_container.chat_container').removeClass('active');
    $('.webinar_room_chat_form_container').removeClass('active');

    $('.webinar_room_speakers_items_container.chat_container[data-tab="' + tabData + '"]').addClass('active');
    $('.webinar_room_chat_form_container[data-tab="' + tabData + '"]').addClass('active');
    $(this).addClass('active');
  });

  $('.user_room_chat_toggle').on('click', function(e) {
    e.stopPropagation();
    e.preventDefault();

    var tabData = $(this).data("tab");

    $('.user_room_chat_toggle').removeClass('active');
    $('.user_room_speakers_items_container.chat_container').removeClass('active');
    $('.user_room_chat_form_container').removeClass('active');

    $('.user_room_speakers_items_container.chat_container[data-tab="' + tabData + '"]').addClass('active');
    $('.user_room_chat_form_container[data-tab="' + tabData + '"]').addClass('active');
    $(this).addClass('active');
  });

  var bs_modal = jquery('#crop_modal');
  var image = jquery('#image_for_crop');
  let fileInputElement = jquery('#user_avatar_image');
  var cropper, reader, file;


  $("body").on("change", "#user_avatar_image", function(e) {
    var files = e.target.files;
    var done = function(url) {
      image.src = url;
      bs_modal.modal('show');
    };

    if (files && files.length > 0) {
      file = files[0];

      fileInputElement.val('');
      reader = new FileReader();
      reader.onload = function(e) {
        image.attr("src", e.target.result);
        done(reader.result);
      };
      reader.readAsDataURL(file);
    }
  });

  bs_modal.on('shown.bs.modal', function() {
    cropper = new Cropper(image[0], {
      aspectRatio: 1,
      viewMode: 1,
      autoCropArea: 1,
      ready: function () {
        croppable = true;
      },
    });
  }).on('hidden.bs.modal', function() {
    cropper.destroy();
    cropper = null;
  });

  $("#crop").click(async function() {
    let imgSrc = cropper.getCroppedCanvas({
      width: 600,
      height: 600,
    }).toDataURL();
    $('#save').show();
  });

  $("#save").click(async function() {
    let imgSrc = cropper.getCroppedCanvas({
      width: 600,
      height: 600,
    }).toDataURL();

    const blob = await (await fetch(imgSrc)).blob();

    let uuid = self.crypto.randomUUID();

    let imageFile = new File([blob], uuid,{type:"image/jpeg", lastModified:new Date().getTime()});

    let fileInputElement = document.getElementById('user_avatar_image');
    let imageContainer = new DataTransfer();
    imageContainer.items.add(imageFile);
    fileInputElement.files = imageContainer.files;

    $('#profile_image_section').attr("src", imgSrc).show();
    bs_modal.modal('hide');
    $('#save').hide();
  });

  $('.question_item_left_save_link, .job_search_content_result_item_bookmark_link, .new_webinar__main_view_actions_item').hover(
    function() {
      $(this).next('.event_item_save_tooltip').addClass('show');
      let tooltipWidth = $(this).next('.event_item_save_tooltip').outerWidth();
      $(this).next('.event_item_save_tooltip').css('left', 'calc(50% - ' + (tooltipWidth / 2 + 3) + 'px)');
    }, function() {
      $(this).next('.event_item_save_tooltip').removeClass('show');
    }
  );

  $('.profile_big_heading.with_dropdown').click(function() {
    $(this).toggleClass('open');
    $(this).parent().prev('.user_profile_data_nav.with_dropdown').toggleClass('open');
    $(this).next('.profile_dropdown_content_container').toggleClass('show');
  });

  $('.new_profile_template_for_student__sidebar_item_heading.with_dropdown').click(function() {
    $(this).toggleClass('open');
    $(this).parent().toggleClass('opened');
    $(this).parent().parent().toggleClass('opened');
    $(this).parent().next('.profile_dropdown_content_container').toggleClass('show');
  });

  // $('.new_users_list__award-btn').on('click', function(e) {
  //   e.preventDefault();
  //   $(this).parent().addClass('awarded');
  // });

  $('.companies-list__item-jobs-award-btn').on('click', function(e) {
    e.preventDefault();
    $(this).parent().addClass('awarded');
  });

  $('.summit-schedule-calendar__sidebar-heading').on('click', function(e) {
    e.stopPropagation();
    e.preventDefault();

    $(this).toggleClass('open');
    $(this).next('.summit_schedule_calendar_categories_list').toggleClass('open');
  });

  $('body').on('click', '.post_modal--v2.profile_edit .dropdown-menu', function (e) {
    e.stopPropagation();
  });

  $('body').on('click', '.post_modal--v2.profile_edit .dropdown-menu .close-btn, .post_modal--v2.profile_edit .dropdown-menu .save-btn', function (e) {
    e.stopPropagation();
    e.preventDefault();
    $('.post_modal--v2.profile_edit .dropdown-menu').removeClass('show');
  });

  $('body').on('click', 'a.profile_picture_editor__menu-item', function (e) {
    e.preventDefault();
    $('a.profile_picture_editor__menu-item').removeClass('active');
    $(this).addClass('active');
    const showTabName = $(this).data('tab');
    $(this).parent().parent().parent().find(`div[data-tab]`).hide();
    $(this).parent().parent().parent().find(`div[data-tab="${showTabName}"]`).show();
  });

  $('body').on('click', '.post_modal--v2.profile_edit .edit-item', function (e) {
    e.preventDefault();
    $(this).parent().parent().parent().parent().parent().find('.modal-dialog').hide();
    $(this).parent().parent().parent().parent().parent().find('.profile_picture_editor').show();
  });

  jquery('[data-toggle="tooltip"]').tooltip()
  $(document).on('keyup', '.login_form_two_factor_input', function(event) {
    if(event.keyCode !== 8 && this.value.length === this.maxLength) {
      $(this).next('.login_form_two_factor_input').focus();
    }
  });

  $(document).on('keydown', '.login_form_two_factor_input', function(event) {
    if (event.keyCode === 8) {
      if (this.value.length === 0){
        $(this).prev('.login_form_two_factor_input').focus();
        event.preventDefault();
      }
    }
  });

  $(".group_profile_item_post_comment_file_input").on("change",function() {
    console.log('ss');
    var $input = $(this);
    var reader = new FileReader();

    var file = $input[0].files[0];
    var fileType = file["type"];
    var validImageTypes = ["image/gif", "image/jpeg", "image/png"];

    var docContainer = $(this).parent().parent().parent().parent().next().next('.group_profile_comment_doc_container');

    var imgContainer = $(this).parent().parent().parent().parent().next('.group_profile_comment_img_container');

    if ($.inArray(fileType, validImageTypes) < 0) {
      imgContainer.removeClass('show');
      docContainer.addClass('show');

      docContainer.find('.group_profile_comment_doc').addClass('show');
      docContainer.find('.group_profile_comment_doc_text').text(file.name);
    } else {
      docContainer.removeClass('show');
      imgContainer.addClass('show');

      reader.onload = function(){
        imgContainer.find('.img_preview').attr("src", reader.result);
      }

      reader.readAsDataURL($input[0].files[0]);
    }
  });

  $('.nav_content_burger_toggle').on('click', function(e) {
    e.stopPropagation();
    e.preventDefault();

    $(this).toggleClass('active');
    $(this).next('.nav_content_mobile').toggleClass('show');
    $(".content_container").toggleClass('show_overlay');
  });

  $('.admin_subnav_burger_toggle').on('click', function(e) {
    e.stopPropagation();
    e.preventDefault();

    $(this).toggleClass('active');
    $(this).parent().next('.admin-panel__menu').toggleClass('show');
  });

  $('.nav_content_more_toggle.mobile_toggle').on('click', function(e) {
    e.stopPropagation();
    e.preventDefault();

    $(this).toggleClass('show');
    $(this).next('.nav_content_mobile_more_container').toggleClass('show');
  });

  $('.connected_item_close_link').on('click', function(e) {
    e.preventDefault();
    $(this).parent().removeClass('show');
  });

  // $('.connect_person_link').on('click', function(e) {
  //   e.preventDefault();
  //   $(this).parent().parent().addClass('hidden');
  //   $(this).parent().parent().next('.connected_item_container').addClass('show');
  // });

  $('.question_answers_see_all_link').on('click', function(e) {
    e.preventDefault();
    $(this).parent().toggleClass('hidden_link');
    $(this).parent().prev('.question_answers_container').toggleClass('hidden_answers');
  });

  $('.group_profile_comment_img_remove.img').on('click', function(e) {
    e.stopPropagation();
    e.preventDefault();

    $(this).prev('.img_preview').attr('src','');
    $(this).parent().parent().removeClass('show');
    $(this).parent().parent().prev('.group_profile_item_post_comment_form').find('.group_profile_item_post_comment_file_input').val("");
  });

  $('.group_profile_comment_img_remove.doc').on('click', function(e) {
    e.stopPropagation();
    e.preventDefault();

    $(this).prev('.group_profile_comment_doc').removeClass('show');
    $(this).parent().parent().removeClass('show');
    $(this).parent().parent().prev().prev('.group_profile_item_post_comment_form').find('.group_profile_item_post_comment_file_input').val("");
  });

  $(document).on('click','.group_profile_content_item_reply_link', function(e) {
    e.preventDefault();

    $(this).parent().next('.reply_comment_form').toggleClass('show');
  });

  $('.summit-schedule-calendar__toogle-item').click(function(e) {
    e.preventDefault();

    $('.summit-schedule-calendar__toogle-item').removeClass('active')

    if ($(this).hasClass('grid-view')) {
      $(this).addClass('active');
      $('.grid-view-content').addClass('active');
      $('.calendar-view-content').removeClass('active');
    }

    if ($(this).hasClass('calendar-view')) {
      $(this).addClass('active');
      $('.grid-view-content').removeClass('active');
      $('.calendar-view-content').addClass('active');
    }
  });

  $('.event_agenda_filters_nav_item').click(function(e) {
    e.stopPropagation();
    e.preventDefault();

    var tabData = $(this).data("tab");

    $(this).parent().find('.event_agenda_filters_nav_item').removeClass('active');
    $(this).addClass('active');

    $('.event_agenda_filters_checkboxes_container').removeClass('active');
    $('.event_agenda_filters_checkboxes_container[data-tab="' + tabData + '"]').addClass('active');
  });

  $('.user_profile_progress_toggle').click(function(e) {
    e.stopPropagation();
    e.preventDefault();

    $(this).parent().toggleClass('collapsed');
  });

  $('.news-feed__menu-item').click(function(e) {
    e.stopPropagation();
    e.preventDefault();

    var tabData = $(this).data("tab");

    $(this).parent().parent().find('.news-feed__menu-item').removeClass('active');
    $(this).addClass('active');

    $('.admin_table_content_toggle').removeClass('active');
    $('.admin_table_content_toggle[data-tab="' + tabData + '"]').addClass('active');
  });

  $('.news-feed__menu-item-webinar').click(function(e) {
    var tabData = $(this).data("tab");

    $(this).parent().parent().find('.news-feed__menu-item').removeClass('active');
    $(this).addClass('active');

    $('.admin_table_content_toggle').removeClass('active');
    $('.admin_table_content_toggle[data-tab="' + tabData + '"]').addClass('active');
  });

  $(document).on('click', '#webinars_nav', function(e) {
    e.stopPropagation();
    e.preventDefault();

    var tabData = $(this).data("tab");

    $(this).parent().find('.news-feed__menu-item-webinar').removeClass('active');
    $(this).addClass('active');

    $(this).parent().parent().parent().find('.admin_table_content_toggle').removeClass('active');
    $('.admin_table_content_toggle[data-tab="' + tabData + '"]').addClass('active');
  });

  $('.dropdown-submenu a.dropdown-toggle').on('click', function(e) {
    e.stopPropagation();
    e.preventDefault();

    if (!$(this).next().hasClass('show')) {
      $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
    }

    var $subMenu = $(this).next(".dropdown-menu");
    $subMenu.toggleClass('show');
    $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
      $('.dropdown-submenu .show').removeClass("show");
    });
    return false;
  });

  $('.contact_modal_row_data_copy').click(function(e) {
    e.stopPropagation();
    e.preventDefault();

    var textForCopy = $(this).text();

    navigator.clipboard.writeText(textForCopy);
  });

  $('.notes_toggle').click(function(e) {
    e.stopPropagation();
    e.preventDefault();

    $('.summit_application_notes_container').toggleClass('show');
    $('.job-management-board__box').toggleClass('notes_open');
  });

  $('.all_jobs_tabs_nav_item').click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    var tabData = $(this).data("tab");

    $('.all_jobs_tabs_nav_item').removeClass('active');
    $('.rss_feeds_items_container.jobs_list').removeClass('active');

    $('.all_jobs_tabs_nav_item[data-tab="' + tabData + '"]').addClass('active');
    $('.rss_feeds_items_container.jobs_list[data-tab="' + tabData + '"]').addClass('active');
  });

  $('.candidates_management_nav_item').click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    var tabData = $(this).data("tab");

    $('.candidates_management_nav_item').removeClass('active');
    $('.candidates_management_candidates_list').removeClass('active');

    $('.candidates_management_nav_item[data-tab="' + tabData + '"]').addClass('active');
    $('.candidates_management_candidates_list[data-tab="' + tabData + '"]').addClass('active');
  });

  $('.summit_form_bottom_nav_link').click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    var tabData = $(this).data("tab");

    $('.summit_form_bottom_nav_link').removeClass('active');
    $('.summit_form_tab_container').removeClass('active');

    $('.summit_form_bottom_nav_link[data-tab="' + tabData + '"]').addClass('active');
    $('.summit_form_tab_container[data-tab="' + tabData + '"]').addClass('active');
  });

  $('.summit_timeline_modal_schedule_btn').click(function() {
    if ($(this).hasClass('active')) {
      var cloningElement = $(this).parent().parent().find('.summit_timeline_modal_schedule_item.active.for_cloning');
    } else if ($(this).hasClass('inactive')) {
      var cloningElement = $(this).parent().parent().find('.summit_timeline_modal_schedule_item.inactive.for_cloning');
    }

    cloningElement.clone(true).appendTo($(this).parent().parent().find('.summit_timeline_modal_schedule_items_container'));

    var clonedElement = $(this).parent().parent().find('.summit_timeline_modal_schedule_items_container .summit_timeline_modal_schedule_item.for_cloning');

    clonedElement.removeClass('for_cloning');

    clonedElement.find('.create_job_form_category_select').selectpicker();
  });

  $('.add_another_panelist').click(function() {
    var panelistCloningElement = $(this).parent().parent().find('.summit_timeline_modal_schedule_item.panelist.for_cloning');

    panelistCloningElement.clone(true).appendTo($(this).parent().parent().find('.summit_timeline_modal_schedule_items_container'));

    var panelistClonedElement = $(this).parent().parent().find('.summit_timeline_modal_schedule_items_container .summit_timeline_modal_schedule_item.for_cloning');

    panelistClonedElement.removeClass('for_cloning');

    panelistClonedElement.find('.summit_timeline_modal_schedule_item_time_select').selectpicker();
  });

  $('.user_settings_form_eye').click(function() {
    var prevInput = $(this).prev('.user_settings_form_input.password');

    if ( prevInput.hasClass('show') ) {
      prevInput.removeClass('show');
      prevInput.attr('type', 'password');
    } else {
      prevInput.addClass('show');
      prevInput.attr('type', 'text');
    }
  });

  $('.user_dashboard_research_item_top').click(function() {
    $(this).toggleClass('open');
    $(this).next('.user_dashboard_research_inner_content').toggleClass('show');
  });

  $('.add_another_headquarters_btn').click(function() {
    var cloningElement = $(this).parent().parent().find('#InputHeadquarters');

    cloningElement.clone(true).appendTo($(this).parent().parent().find('.another_headquarters_inputs_container')).addClass('mt-3');
  });

  $('.duplicate_field').click(function() {
    let type = $(this).data('type') || 'generic'
    let clone = ''
    if (type == 'company') {
      clone = `<select name="company[address][${$('[name*="company[address]["][type="hidden"]').length}]" placeholder="Headquarters" class="form-control duplicateable_address mt-3"></select>`
    } else {
      let cloningElement = $('.'+$(this).data('class')).last();
      clone = cloningElement.clone()
      cloningElement.val("");
    }
    $(this).siblings().append(clone)
    if(typeof initAutocomplete == 'function') {
      initAutocomplete()
    }
  });

  $('.user_dashboard_tabs_toggle_item').click(function(e) {
    e.stopPropagation();
    e.preventDefault();

    var tabData = $(this).data("tab");

    $(this).parent().find('.user_dashboard_tabs_toggle_item').removeClass('active');
    $(this).addClass('active');

    $(this).parent().parent().parent().find('.user_dashboard_tabs_content').removeClass('active');
    $('.user_dashboard_tabs_content[data-tab="' + tabData + '"]').addClass('active');
  });

  $('a.job-board-modal__item').on('click', function(e) {
    e.preventDefault();
    $(this).toggleClass('active')
  });

  if ( $("#rss_daterange_input").length ) {
    let daterangepickerModule = jquery('#rss_daterange_input').data('module') || 'events'
    jquery('#rss_daterange_input').daterangepicker({
      autoUpdateInput: true,
      locale: {
        format: 'MMM D, YYYY'
      }
    }).on('cancel.daterangepicker', function() {
      $(this).val('');
      sendFilterRequest();
    }).val('').change(sendFilterRequest);

    function sendFilterRequest() {
      let activeTab = $('.news-feed__menu-item.active').data('tab');
      let search = $('#search, #searchNews').val() || '';
      let category = $('#category').val() || '';
      let sort = $('select.activity_feed_sort_select').val() || '';
      let date = $("#rss_daterange_input").val() || '';

      $.ajax({
        url: `/${daterangepickerModule}/filter`,
        type: "POST",
        dataType: 'script',
        data: { search, category, sort, date },
        success: function () {
          $('.tab-pane.admin_table_content_toggle').removeClass('active');
          $('.tab-pane.admin_table_content_toggle[data-tab="' + activeTab + '"]').addClass('active');
        }
      })
    }
  }

  if ( $("#create_job_salary_min").length ) {
    var lowerSlider = document.querySelector('#create_job_salary_min'); //Lower value slider
    var upperSlider = document.querySelector('#create_job_salary_max'); //Upper value slider

    var lowerVal = parseInt(lowerSlider.value); //Value of lower slider
    var upperVal = parseInt(upperSlider.value); // Value of upper slider

    var rangeColor = document.querySelector('#create_job_salary_range_color'); //Range color

    var lowerSliderMax = document.querySelector('#create_job_salary_min').max;
    var upperSliderMax = document.querySelector('#create_job_salary_max').max;

    var lowerSliderMaxVal = (lowerSliderMax/100);
    var upperSliderMaxVal = (upperSliderMax/100);

    rangeColor.style.marginLeft = (lowerSlider.value)/lowerSliderMaxVal + '%';
    rangeColor.style.width = (upperSlider.value/upperSliderMaxVal) - (lowerSlider.value/lowerSliderMaxVal) + '%';

    $("#create_job_salary_min_number").val(lowerSlider.value);
    $("#create_job_salary_max_number").val(upperSlider.value);

    //When the upper value slider is moved.
    upperSlider.oninput = function() {
       lowerVal = parseInt(lowerSlider.value); //Get lower slider value
       upperVal = parseInt(upperSlider.value); //Get upper slider value



      var lowerSliderMax = document.querySelector('#create_job_salary_min').max;
      var upperSliderMax = document.querySelector('#create_job_salary_max').max;

      var lowerSliderMaxVal = (lowerSliderMax/100);
      var upperSliderMaxVal = (upperSliderMax/100);

      rangeColor.style.marginLeft = (lowerSlider.value)/lowerSliderMaxVal + '%';
      rangeColor.style.width = (upperSlider.value/upperSliderMaxVal) - (lowerSlider.value/lowerSliderMaxVal) + '%';


       //If the upper value slider is LESS THAN the lower value slider plus one.
       if (upperVal < lowerVal ) {
          //The lower slider value is set to equal the upper value slider minus one.
          lowerSlider.value = upperVal ;

          $("#create_job_salary_min_number").val(lowerSlider.value);

          //If the lower value slider equals its set minimum.
          if (lowerVal === lowerSlider.min) {
             //Set the upper slider value to equal 1.
             upperSlider.value = 1;

             $("#create_job_salary_max_number").val(upperSlider.value);
          }
       }


       //Setting the margin left of the middle range color.
       //Taking the value of the lower value and then turning it into a percentage.
       rangeColor.style.marginLeft = (lowerSlider.value/lowerSliderMaxVal) + '%';

       //Setting the width of the middle range color.
       //Taking the value of the upper value and subtracting the lower value and then turning it into a percentage.
       rangeColor.style.width = (upperSlider.value/upperSliderMaxVal) - (lowerSlider.value/lowerSliderMaxVal) + '%';

       $("#create_job_salary_max_number").val(upperSlider.value);
    };

    //When the lower value slider is moved.
    lowerSlider.oninput = function() {
       lowerVal = parseInt(lowerSlider.value); //Get lower slider value
       upperVal = parseInt(upperSlider.value); //Get upper slider value

      var lowerSliderMax = document.querySelector('#create_job_salary_min').max;
      var upperSliderMax = document.querySelector('#create_job_salary_max').max;

      var lowerSliderMaxVal = (lowerSliderMax/100);
      var upperSliderMaxVal = (upperSliderMax/100);

      rangeColor.style.marginLeft = (lowerSlider.value)/lowerSliderMaxVal + '%';
      rangeColor.style.width = (upperSlider.value/upperSliderMaxVal) - (lowerSlider.value/lowerSliderMaxVal) + '%';


       //If the lower value slider is GREATER THAN the upper value slider minus one.
       if (lowerVal > upperVal ) {
          //The upper slider value is set to equal the lower value slider plus one.
          upperSlider.value = lowerVal ;

          $("#create_job_salary_max_number").val(upperSlider.value);

          //If the upper value slider equals its set maximum.
          if (upperVal === upperSlider.max) {
             //Set the lower slider value to equal the upper value slider's maximum value minus one.
             lowerSlider.value = parseInt(upperSlider.max) ;

             $("#create_job_salary_min_number").val(lowerSlider.value);
          }

       }

       //Setting the margin left of the middle range color.
       //Taking the value of the lower value and then turning it into a percentage.
       rangeColor.style.marginLeft = (lowerSlider.value/lowerSliderMaxVal) + '%';

       //Setting the width of the middle range color.
       //Taking the value of the upper value and subtracting the lower value and then turning it into a percentage.
       rangeColor.style.width = (upperSlider.value/upperSliderMaxVal) - (lowerSlider.value/lowerSliderMaxVal) + '%';

       $("#create_job_salary_min_number").val(lowerSlider.value);
    };
  }

  $(".datepicker").each(function() {
    let format = $(this).data('format') || 'mmm d, yyyy';
    $(this).datepicker({
          uiLibrary: 'bootstrap4',
          format: format,
          footer: true,
          open: function (e) {
            console.log(e)
            new Tether({
              element: $('.gj-picker[role="calendar"]')[0],
              target: $(this)[0],
              attachment: 'bottom left',
              targetAttachment: 'top left'
            })
          }
        }
    );
  });

  $(document).ajaxComplete(function () {
    $('input.emojionearea, textarea.emojionearea').each(function () {
      jquery(this).emojioneArea();
    })
  });

  $('input.emojionearea, textarea.emojionearea').each(function () {
    jquery(this).emojioneArea();
  })

  $("input.mentiony").each(function () {
    $(this).mentiony({
      onDataRequest: function (
        mode,
        keyword,
        onDataRequestCompleteCallback
      ) {
        $.get(`/api/users?kw=${keyword}`, null, function(data){
          // Call this to populate mention.
          onDataRequestCompleteCallback.call(this, data);
        })

      },
      timeOut: 0,
      debug: 1,
      onKeyUp: function(event, oldInputEle) {
        console.log(oldInputEle.val())

      }
    })
  })

  $('.timepicker').each(function() {
    let format = $(this).data('timepicker-format') || 'h:MM TT'
    $(this).timepicker({
      format,

      close: function (e) {
        var timeData = $(this).val();
        var firstNumber = $(this).val().slice(0,1);

        if (firstNumber == "0") {
          newTimeData = timeData.replace('0', '12');

          $(this).val(newTimeData);
        }
      }
    });
  });

  $('.rss_feeds_filters_toggle').on('click', function() {
    $(this).next('.rss_feeds_filters_dropdown').toggleClass('show');
  });

  $('.job_search_left_filters_heading').on('click', function() {
    $(this).toggleClass('show');
    $(this).next('.job_search_left_filters_block_checkboxes_container').toggleClass('show');
  });

  $('.companies-list__filter-header').on('click', function() {
    $(this).parent().toggleClass('open');
  });

  $('#projects_ongoing_project_checkbox').change(function() {
    if ($(this).prop('checked')) {
      $('.project_end_date_calendar').addClass('hidden');
      $('.project_end_date_input').removeClass('hidden');
    } else {
      $('.project_end_date_calendar').removeClass('hidden');
      $('.project_end_date_input').addClass('hidden');
    }
  });

  $('.job_management_board_filter_role_checkbox').change(function() {
    var parentContainer = $(this).parent().parent().parent().parent();

    parentContainer.find('.job_management_board_filter_role_dropdown_text').text($(this).val());
    parentContainer.find('.job_management_board_filter_role_dropdown_text').removeClass('open').removeClass('paused').removeClass('member').removeClass('admin');

    if ($(this).val() == 'Member' || $(this).val() == 'Manager') {
      parentContainer.find('.job_management_board_filter_role_dropdown_text').addClass('member');
    } else if ($(this).val() == 'Admin' || $(this).val() == 'Representative') {
      parentContainer.find('.job_management_board_filter_role_dropdown_text').addClass('admin');
    } else if ($(this).val() == 'Open') {
      parentContainer.find('.job_management_board_filter_role_dropdown_text').addClass('open');
    } else if ($(this).val() == 'Paused') {
      parentContainer.find('.job_management_board_filter_role_dropdown_text').addClass('paused');
    }
  });

  $('.project_code_access_select').change(function() {
    if ($(this).val() == 'Private') {
      $('.project_code_link_input').prop("disabled", true);
    } else {
      $('.project_code_link_input').prop("disabled", false);
    }
  });

  $('.announcements_content_item_dismiss_link').on('click', function() {
    var announcementId = $(this).data("announcement-id");

    $('.announcements_notification_container[data-announcement-id="' + announcementId + '"]').removeClass('show');
  });

  $('.announcements_notification_close').on('click', function() {
    const announcementId = this.getAttribute('data-announcement-id');

    fetch('/feed/close', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ closed_announcement_id: announcementId })
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        $('.announcements_notification_container[data-announcement-id="' + announcementId + '"]').removeClass('show');
      } else {
        console.error('Error closing announcement.');
      }
    })
    .catch(error => {
      console.error('Error closing announcement:', error);
    });
  });

  $('.personal-profile__menu-item').click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    var tabData = $(this).data("tab");
    if (tabData == 'groups'){
      $('#company_action_buttons').attr("style", "display:none !important");
    }else{
      $('#company_action_buttons').attr("style", "display:block !important");
    }
    $('.personal-profile__menu-item').removeClass('active');
    $(this).addClass('active');
    $('.personal_profile_tab').removeClass('active');
    $('.personal_profile_tab[data-tab="' + tabData + '"]').addClass('active');
  });

  $('.user_profile_data_nav_item').click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    var tabData = $(this).data("tab");

    if (typeof $(this).data('tab-parent') !== 'undefined') {
      let tabParentClass = $(this).data("tab-parent");
      $( `${tabParentClass} .user_profile_data_nav_item`).removeClass('active');
      $( `${tabParentClass} .user_profile_data_tab`).removeClass('active');
    } else {
      $('.user_profile_data_nav_item').removeClass('active');
      $('.user_profile_data_tab').removeClass('active');
    }

    $(this).addClass('active');
    $('.user_profile_data_tab[data-tab="' + tabData + '"]').addClass('active');
  });

  $('.modal-body__content-item').click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    var tabData = $(this).data("tab");

    let tabParentClass = $(this).data("tab-parent");
    $( `${tabParentClass} .modal-body__content-item`).removeClass('active');
    $( `${tabParentClass} .video_link_data_tab`).removeClass('active');

    $(this).addClass('active');
    console.log(`${tabParentClass} .video_link_data_tab[data-tab="${tabData}"]`);
    $(`${tabParentClass} .video_link_data_tab[data-tab="${tabData}"]`).addClass('active');
  });

  $('.chatGroupModal__tab-item').click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    var tabData = $(this).data("tab");

    if (typeof $(this).data('tab-parent') !== 'undefined') {
      let tabParentClass = $(this).data("tab-parent");
      $( `${tabParentClass} .chatGroupModal__tab-item`).removeClass('active');
      $( `${tabParentClass} .chatGroupModal__data-tab`).removeClass('active');
    } else {
      $('.chatGroupModal__tab-item').removeClass('active');
      $('.chatGroupModal__data-tab').removeClass('active');
    }

    $(this).addClass('active');
    $('.chatGroupModal__data-tab[data-tab="' + tabData + '"]').addClass('active');
  });

  $('.companies-list__grid-icon--row').click(function(e) {
    e.preventDefault();
    $('.companies-list__grid-icon, .companies-list__grid-icon2').removeClass('active');
    $(this).addClass('active');
    $('.companies-list-items > div, .people-list-items > div').removeClass('col-xl-6 grid');
    $('.companies-list-items > div, .people-list-items > div').addClass('col-xl-12');
    document.cookie="grid=false";
  });

  $('.companies-list__grid-icon--grid').click(function(e) {
    e.preventDefault();
    $('.companies-list__grid-icon, .companies-list__grid-icon2').removeClass('active');
    $(this).addClass('active');
    $('.companies-list-items > div, .people-list-items > div').removeClass('col-xl-12');
    $('.companies-list-items > div, .people-list-items > div').addClass('col-xl-6 grid');
    document.cookie="grid=true";
  });

  $('.job_search_content_sort_view_toggle_item').click(function(e) {
    e.stopPropagation();
    e.preventDefault();

    var tabData = $(this).data("view");

    $('.job_search_content_sort_view_toggle_item').removeClass('active');
    $('.job_search_content_result_container').removeClass('grid').removeClass('row');

    $('.job_search_content_sort_view_toggle_item[data-view="' + tabData + '"]').addClass('active');
    $('.job_search_content_result_container').addClass(tabData);
  });

  $('.management_board_link').click(function(e) {
    e.stopPropagation();
    e.preventDefault();

    var tabData = $(this).data("tab");

    $('.management_board_link').removeClass('active');
    $('.management_board_item').removeClass('active');

    $('.management_board_link[data-tab="' + tabData + '"]').addClass('active');
    $('.management_board_item[data-tab="' + tabData + '"]').addClass('active');
  });

  $('#job-management-board-check_all').change(function() {
    var checkBoxes = $('.job-management-board-checkbox');

    if ($(this).prop('checked')) {
      checkBoxes.prop('checked', true);
    } else {
      checkBoxes.prop('checked', false);
    }
  });

  $('#documents-table-check_all').change(function() {
    var checkBoxes = $('.documents_table .documents_table_checkbox');

    if ($(this).prop('checked')) {
      checkBoxes.prop('checked', true);
    } else {
      checkBoxes.prop('checked', false);
    }
  });

  $('.groups_list_filters_toggle').on('click', function() {
    $(this).next('.groups_list_filters_dropdown').toggleClass('show');
  });

  $('.company_profile_content_right_bottom_link.more_details').click(function(e) {
    e.preventDefault();

    $(this).toggleClass('open');
    $(this).parent().parent().find('.company_about_more_content').toggleClass('open');

    if ($(this).hasClass('details')) {
      if($(this).hasClass('open')) {
        $(this).text('View Less Details');
      } else {
        $(this).text('View More Details');
      }
    } else if ($(this).hasClass('companies')) {
      if($(this).hasClass('open')) {
        $(this).text('View less similar companies');
      } else {
        $(this).text('View all similar companies');
      }
    } else if ($(this).hasClass('connections')) {
      if($(this).hasClass('open')) {
        $(this).text('View less connections');
      } else {
        $(this).text('View all connections');
      }
    }
    else if ($(this).hasClass('events')) {
      if($(this).hasClass('open')) {
        $(this).text('View less events');
      } else {
        $(this).text('View all events');
      }
    }
    else if ($(this).hasClass('jobs')) {
      if($(this).hasClass('open')) {
        $(this).text('View less jobs');
      } else {
        $(this).text('View all jobs');
      }
    }
    else if ($(this).hasClass('webinars')) {
      if($(this).hasClass('open')) {
        $(this).text('View less webinars');
      } else {
        $(this).text('View all webinars');
      }
    }
    else if ($(this).hasClass('ergs')) {
      if($(this).hasClass('open')) {
        $(this).text('View Fewer Employee Resource Groups');
      } else {
        $(this).text('View More Employee Resource Groups');
      }
    }
  });

  $('body').on('click', '.messages-center__chats-toggle-item', function(e) {
    e.stopPropagation();
    e.preventDefault();
    var tabData = $(this).data("tab");

    $('.messages-center__chats-toggle-item').removeClass('active');
    $(this).addClass('active');
    $('.messages_left_part_container').removeClass('active');
    $('.messages_left_part_container[data-tab="' + tabData + '"]').addClass('active');
  });

  $('.messages-center__group-title-link').click(function(e) {
    e.stopPropagation();
    e.preventDefault();

    $(this).parent().parent().toggleClass('closed');
  });

  $('.company_profile_content_left_item_bottom_nav_item').click(function(e) {
    e.stopPropagation();
    e.preventDefault();

    var tabData = $(this).data("tab");

    $('.company_profile_content_left_item_bottom_nav_item').removeClass('active');
    $('.company_profile_content_left_item').removeClass('active');

    if (tabData === 'overview') {
      $('.company_profile_content_left_item_bottom_nav_item[data-tab="' + tabData + '"]').addClass('active');
      $('.company_profile_content_left_item:not(.events)').addClass('active');
    } else {
      $('.company_profile_content_left_item_bottom_nav_item[data-tab="' + tabData + '"]').addClass('active');
      $('.company_profile_content_left_item[data-tab="' + tabData + '"]').addClass('active');
    }
  });

  $('.post_modal_bottom_link').click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    var tabData = $(this).data("tab");

    if (tabData === 'photo') {
      $('.post_modal_heading_text').text('Select/Edit your photo');
    } else if (tabData === 'video') {
      $('.post_modal_heading_text').text('Select/Edit your video');
    } else if (tabData === 'document') {
      $('.post_modal_heading_text').text('Share a document');
    }

    $('.post_modal_author_container').removeClass('show');
    $('.post_modal_text_container').removeClass('show');
    $('.post_modal_bottom_container').removeClass('show');

    $('.post_modal_upload_container[data-tab="' + tabData + '"]').addClass('show');
    $('.post_modal_upload_cloud_container[data-tab="' + tabData + '"]').addClass('show');
    $('.post_modal_bottom_container.upload').addClass('show');
  });

  $('.post_modal_bottom_back_link').click(function(e) {
    e.stopPropagation();
    e.preventDefault();

    $('.post_modal_heading_text').text('Create a post');

    $('.post_modal_upload_container').removeClass('show');
    $('.post_modal_upload_cloud_container').removeClass('show');
    $('.post_modal_bottom_container').removeClass('show');

    $('.post_modal_author_container').addClass('show');
    $('.post_modal_text_container').addClass('show');
    $('.post_modal_bottom_container.initial').addClass('show');
  });

  $('.post_modal_bottom_submit_btn.upload').click(function(e) {
    e.stopPropagation();
    e.preventDefault();

    $('.post_modal_heading_text').text('Create a post');

    $('.post_modal_upload_container').removeClass('show');
    $('.post_modal_upload_cloud_container').removeClass('show');
    $('.post_modal_bottom_container').removeClass('show');

    $('.post_modal_author_container').addClass('show');
    $('.post_modal_text_container').addClass('show');
    $('.post_modal_bottom_container.initial').addClass('show');
  });

  $('.company_profile_tabs_nav_item').click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    var tabData = $(this).data("tab");
    $('.company_profile_tabs_nav_item').removeClass('active');
    $(this).addClass('active');
    $('.company_profile_tab').removeClass('active');
    $('.company_profile_tab[data-tab="' + tabData + '"]').addClass('active');
  });

  $('.activity_feed_top_nav_toggle_item').click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    var tabData = $(this).data("tab");

    $('.activity_feed_top_nav_toggle_item').removeClass('active');
    $(this).addClass('active');

    if (tabData === "all") {
      $('.company_profile_content_left_item').removeClass('hidden');
      $('.announcements_content_item').removeClass('hidden');
    } else {
      $('.company_profile_content_left_item').addClass('hidden');
      $('.company_profile_content_left_item[data-tab="' + tabData + '"]').removeClass('hidden');
    }

    if (tabData === "questions") {
      $('#create-post-form .emojionearea-editor').attr("placeholder", "Ask a question");
      $('#post-text').text("Ask a question");
      $('.activity_feed_post_form_question_container').addClass('show');
      $('.activity_feed_questions_container').addClass('show');
      $('.announcements_content_item').addClass('hidden');
    } else {
      $('#create-post-form .emojionearea-editor').attr("placeholder", "Start a post");
      $('.activity_feed_post_form_question_container').removeClass('show');
    }

    if (tabData === "posts") {
      $('.announcements_content_item').addClass('hidden');
      $('.activity_feed_questions_container').removeClass('show');
    }
  });

  $('.activity_feed_post_form_question_details_btn').click(function(e) {
    e.stopPropagation();
    e.preventDefault();

    $(this).addClass('hide');
    $(this).parent().prev('.activity_feed_post_form_question_textarea_container').addClass('show');
  });

  if ($('#company_top_data_container').length > 0) {
    var companyHeightThreshold = $('#company_top_data_container').offset().top;
  }

  $.calculateAge = date => {
    let ageDifMs = Date.now() - date.getTime()
    let ageDate = new Date(ageDifMs)
    return Math.abs(ageDate.getUTCFullYear() - 1970)
  }

  $(window).scroll(function() {
    var scroll = $(window).scrollTop();

    if ($('#company_top_data_container').length > 0) {
      if (scroll >= companyHeightThreshold) {
        $('#company_top_scroll_container').addClass('on_scroll');
      } else {
        $('#company_top_scroll_container').removeClass('on_scroll');
      }
    }
  });

  $('.user_dashboard_nav_link').not('.settings').click(function(e) {
    e.preventDefault();

    var tabData = $(this).parent().data("tab");

    $('.user_dashboard_nav_link_container').removeClass('active');
    $('.user_dashboard_content_container').removeClass('active');

    $('.user_dashboard_nav_link_container[data-tab="' + tabData + '"]').addClass('active');
    $('.user_dashboard_content_container[data-tab="' + tabData + '"]').addClass('active');
  });

  $('.user_dashboard_nav_link.open-in-new-tab').click(function(e) {
    e.preventDefault();
    window.open($(this).attr('href'), '_blank');
  });

  $('body').on('click', function(e) {
    var summit_calendar_search = $(".summit_schedule_filters_search_input_container");

    if (!summit_calendar_search.is(e.target) && summit_calendar_search.has(e.target).length === 0) {
      summit_calendar_search.removeClass('show');
      $('.calendar_search_b').addClass('show');
    }
  });

  $(document).on('change', '#event_company_eventable_id, #event_research_eventable_id, #event_school_eventable_id, #event_group_eventable_id, #event_community_eventable_id', function() {
    var array = ['event_company_eventable_id', 'event_research_eventable_id', 'event_school_eventable_id', 'event_group_eventable_id', 'event_community_eventable_id'];
    var i = 0, len = array.length;
    while (i < len) {
      if(array[i] !== this.id){
        $('#' + array[i]).val('');
        if ($('#' + array[i]).parent().hasClass('bootstrap-select')) {
          $('#' + array[i]).selectpicker('refresh');
        }
      }
      i++;
    }
  });
});
