$(document).on('turbolinks:load', function() {
  $('body').on('click', '.item_folder_show_saved_items_list', (e)=>{
    $.ajax({
      url: `/item_folders/${$(e.target).data('itemFolderId')}/user_specific/${$(e.target).data('userId')}`,
      type: 'GET',
      dataType: 'script'
    })
  });

  $('body').on('keyup', '#search_list_input', function(e){
    $.ajax({
      url: '/lists',
      type: "GET",
      dataType: 'script',
      data: { keyword: $(this).val() }
    });
  });
});
