import Sortable from 'sortablejs';

$(document).on('turbolinks:load', function() {

  if ($('#image-image').length > 0) {
    const image = $('#image-image')[0];
    const cropper = new Cropper(image, {
      aspectRatio: 1,
      viewMode: 1,
    });

    const rangeInput = $('.zoom_range');
    rangeInput.on('input', function() {
      const zoomValue = parseFloat(rangeInput.val());
      const zoomRatio = zoomValue / 100;
      cropper.zoomTo(zoomRatio);
    });

    $(".rotate-left-button").click(function(e) {
      e.preventDefault();
      cropper.rotate(-90);
    });

    $(".rotate-right-button").click(function(e) {
      e.preventDefault();
      cropper.rotate(90);
    });

    const rangeInputRotate = $('#formControlRangeRotate');
    rangeInputRotate.on('input', function() {
      const rotationValue = parseFloat(rangeInputRotate.val());
      cropper.rotateTo(rotationValue);
    });

    $('.filters__item').click(function() {
      const filter = $(this).data('filter');
      cropper.reset();

      switch (filter) {
        case 'Studio':
         image.css('filter', 'brightness(120%) contrast(120%)');
          break;
        case 'Spotlight':
          applySpotlightFilter();
          break;
        default:
        break;
      }
    });

    function applyStudiFilter() {
      const filterSettings = {
        brightness: 100,
        contrast: 1.2,
      };
      cropper.setOptions({
        style: filterSettings,
      });
    }

    $("#save_crop_image_button").click(function(e) {
      e.preventDefault();
      var url = $(this).data('url');
      var activeFilter = $('.filters__item.active').data('filter');
      const croppedCanvas = cropper.getData();

      $.ajax({
        method: 'POST',
        url: url,
        data: {
          x: croppedCanvas.x,
          y: croppedCanvas.y,
          width: croppedCanvas.width,
          height: croppedCanvas.height,
          rotate: croppedCanvas.rotate,
          filter: activeFilter
        }
      });
    });
  }

  const doughnutChart = ()=>{
    new Chart($('#doughnut-chart-3'), {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: $('#doughnut-chart-3').data('values'),
            borderColor: ["#EE7742", "#53B1FD", "#59CDEE", "#9B8AFB", "#FAC628"],
            backgroundColor: ["#EE7742", "#53B1FD", "#59CDEE", "#9B8AFB", "#FAC628"],
            borderWidth: 0
          }
        ]
      },
      options: {
        title: { display: false},
        responsive: true,
        cutout: 32
      }
    });
  }

  if($('#doughnut-chart-3').length > 0){
    doughnutChart()
  }

  $( ".checkboxI, .company_industry_filter").click(function() {
    var s_filters = $('.checkboxI');
    var i_filters = $('.company_industry_filter');
    var s_filters_length = s_filters.length;
    var i_filters_length = i_filters.length;
    const array = [];
    var industry_categories = [];
    for (let i = 0; i < s_filters_length; i++) {
      if (s_filters[i].checked) {
        var arr = s_filters[i].parentElement.textContent.trim().split(' (')[0];
        array.push(arr)
      }
    }
    for (let i = 0; i < i_filters_length; i++) {
      if (i_filters[i].checked) {
        var arr = i_filters[i].parentElement.textContent.trim().split(' (')[0];
        industry_categories.push(arr)
      }
    }
    var search = $('.organization_search').val()
    $.ajax({
      type: "GET",
      data: {
        search: search,
        array: array,
        industry_categories: industry_categories,
      },
      dataType: 'script',
      success: function(msg){
      }
    });
  });

  $('.organization_search').on('keyup', (e)=> {
    filter_companies(e)
  })

  $('[name="location_search"]').autoComplete({
    bootstrapVersion: '4',
    minLength: 2,
    resolverSettings: {
      url: '/cities/filter',
      fail: () => {}
    }
  }).on('autocomplete.select', e => {
    filter_companies(e)
  });

  function filter_companies(e){
    let search = $('.organization_search').val();
    let location = $('.location_search').val();

    var length = $('.checkboxI').length;
    var element = $('.checkboxI');

    const array =[]
    for (let i = 0; i < length; i++) {
      if (element[i].checked) {
        arr = element[i].parentElement.textContent.trim().split('(')[0]
        array.push(arr)
      }
    }
    var minlength = 1;
    var th = $(e.target);
    if (th.val().length >= minlength || e.keyCode === 8 ) {
      $.ajax({
        type: "GET",
        url: th.data('url'),
        data: {
          search,
          location,
          array
        },
        dataType: 'script',
        success: function(msg){
        }
      });
    }
  }

  $(document).on('click', '#add_new_company_user', function(){
    // event.preventDefault();
    var type;
    if($(this).data('type') === 'Research'){
      type = "research_centers";
    }
    else if($(this).data('type') === "Company"){
      type = "companies";
    }
    else{
      type = "schools";
    }

    var email = $('#new_company_user_email').val();
    $.ajax({
      url: '/admin/'+ type +'/' + $(this).data('id') + '/invite_user',
      type: "POST",
      data: {
        invitation_email: email,
        authenticity_token: $('meta[name=csrf-token]').attr('content')
      },
    });
  });

  $(document).on('keyup', '#search_to_invite_company_user', function(e){
    if (e.key === "Enter") {
      return;
    }
    var search = $(this).val();
    $.ajax({
      url: '/admin/search_to_invite_user',
      type: "GET",
      dataType: 'script',
      data: {
        search,
        id: $(this).data('id'),
        type: $(this).data('type'),
        authenticity_token: $('meta[name=csrf-token]').attr('content')
      },
    });
  });

  $(document).on('click', '.invite_company_user', function(e){
    e.preventDefault();
    var user_id = $(this).attr('id');
    var $this = $(this)
    $.ajax({
      url: '/admin/manager_users/'+ $(this).data('objId') +'/invite_user',
      type: "POST",
      data: {
        user_id,
        type: $(this).data('type'),
        authenticity_token: $('meta[name=csrf-token]').attr('content')
      },
      error: res => {
        if (res.responseJSON.message) {
          Swal.fire({
            title: 'Error!',
            text: res.responseJSON.message,
            icon: 'error'
          })
          $this.removeClass('d-none');
          $this.next().addClass('d-none');
        }
      }
    });
    $(this).addClass('d-none');
    $(this).next().removeClass('d-none');
  });

  $('#send-invite-companies').click(function(){
    var email = $('#input-email-companies').val();
    const id = window.location.pathname.split('/')[3];
    const pattern = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let role = document.getElementById("user_role").value.toLowerCase();
    if(email.length  == 0){
      alert("please enter email address ");
    }
    else if (role.trim() === "") { 
      alert("Please select role.");
    }
    else{
      if(email.match(pattern)){
        $.ajax({
          url: '/admin/companies/'+ id +'/invite_user',
          data: { email,role },
          method: 'GET',
          success: function (data) {
            console.log(data)
          },
          error: function (data) {
            console.log(data)
          }
        })
      }
      else{
        alert("please enter valid email address like 'abc@gmail.com'");
      }
    }
  })

  if($('.show-jobs').length){
    $('html,body').animate({
          scrollTop: $(".show-jobs").offset().top},
        'slow');
  }

  $(document).on('change', '#analytics_item_select', function(e){
    var selectedValue = this.value;
    var analyticsPath = this.dataset.analyticsPath;
    $.ajax({
      url: analyticsPath + '?days_count=' + selectedValue,
      type: 'GET',
      dataType: 'script',
      success: function(data) {
        $('#analytics_item_select').selectpicker();
        doughnutChart();
      },
    });
  });

  $(document).on('click', '#rank_company', function(e){
    var selectedValue = this.dataset.companyId;
    $.ajax({
      url: '/companies/'+ selectedValue +'/user_ranking',
      data: { rank: this.dataset.rank },
      type: 'POST',
      dataType: 'script',
      success: function(data) {
        $('.rank_company').selectpicker();
      },
    });
  });

  if ( document.getElementById('company-all-rankings') ) {
    var el         = document.getElementById("company-all-rankings");
    var company_id = el.getAttribute('data-company-id');
    var sortable   = Sortable.create(el, {
      delay: 200,
      touchStartThreshold: 5,
      delayOnTouchOnly: false,
      onEnd: function(event) {
        var rankings_order = [];
        $(".rankings__item").each(function(e) {
          rankings_order.push($(this).attr('id').match(/(\d+)/g)[0])
        })
        $.ajax({
          type: "PATCH",
          dataType: 'script',
          url: "/companies/"+ company_id +"/update_rankings_position",
          data: {
            new_ranking_ids_order: rankings_order
          }
        });
      }
    });
  }

  var filterItems = document.querySelectorAll('.filters__item');
  filterItems.forEach(function (item) {
    item.addEventListener('click', function () {
      filterItems.forEach(function (otherItem) {
        otherItem.classList.remove('active');
      });
      item.classList.add('active');
    });
  });

});
