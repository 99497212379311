$(document).on('turbolinks:load', function() {

    $('body').on('change', '.permission', (e)=>{
        var th = $(e.target);

        $.ajax({
            url: '/admin/'+ th.data('type') +'/'+ th.data('id') +'/permission_change',
            type: 'GET',
            data: {permission: th.val()},
            dataType: 'script'
        })
    });
  var minlength = 3;

  $('body').on('keyup', '.organization_search_field', (e)=> {
    var th = $(e.target);
    if (th.val().length >= minlength || e.keyCode === 8 ) {
      $.ajax({
          type: "GET",
          url: th.data('url'),
          data: {
              'search' : th.val()
          },
          dataType: 'script',
          success: function(msg){
          }
      });
    }
  });

});
